import React from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";

export default function MeasureGuide(props) {
  return (
    <div className="w-full px-4 pt-2">
      <div className="mx-auto w-full max-w-xl rounded-lg bg-brand-100 p-4">
        <Disclosure defaultOpen={true}>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-md bg-white px-5 py-2 text-left text-sm font-medium text-brand-900 hover:bg-brand-100 focus:outline-none focus-visible:ring focus-visible:ring-brand-500/75">
                <h4 className="font-sans font-bold text-lg text-brand-600">
                  Framing with a mat border
                </h4>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-8 w-8 text-brand-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-2 pt-4 text-base text-stone-700">
                We will cut the mat window opening {this.props.mat_overlap / 2}
                mm smaller than your measurements on each side. This allows you
                to attach your picture to the mat while ensuring your picture
                won't fall through the opening.
                <div className="bg-yellow-100 my-4 ">
                  <div className="p-4">
                    If you don't want the mat to overlap your artwork, simply
                    add 6mm to your width and height dimensions after measuring.
                  </div>
                </div>
                <strong>How to measure: </strong>
                Measure the width and height of the area you would like to
                frame. If your artwork has empty space around it and you want to
                hide this, use the dimensions of the image you want to be
                visible.
                <div className="grid grid-cols-2 mt-3">
                  <div className="text-center text-muted">
                    <img src={props.img_help_1} className="img-fluid" />
                    <span className="smallish">
                      Frame the entire artwork by entering the outside paper
                      dimensions.
                    </span>
                  </div>
                  <div className="text-center text-muted">
                    <img src={props.img_help_2} className="img-fluid" />
                    <span className="smallish">
                      Frame a portion of your artwork by entering the dimensions
                      of the area you wish to be visible.
                    </span>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-md bg-white px-5 py-2 text-left text-sm font-medium text-brand-900 hover:bg-brand-100 focus:outline-none focus-visible:ring focus-visible:ring-brand-500/75">
                <h4 className="font-sans font-bold text-lg text-brand-600">
                  Frame only
                </h4>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-8 w-8 text-brand-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-2 pt-4  text-stone-700">
                The frame edge will overlap your artwork by 5mm.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}
