import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-responsive-modal";

class ShippingEstimate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      success: false,
      estimate: props.shipping_estimates[0],
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  openModal() {
    segment_track("Shipping Modal Opened", { location: this.props.location });
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  onChange(e) {
    let val = parseInt(e.target.value);
    this.setState({
      estimate: this.props.shipping_estimates.find((e) => e.id == val),
    });
  }

  render() {
    return (
      <div className="f">
        {this.props.location == "cart" ? (
          <button
            type="button"
            onClick={this.openModal}
            className="text-brand-600 underline text-xs"
          >
            Delivery Info
          </button>
        ) : (
          <button
            type="button"
            onClick={this.openModal}
            className="btn block w-full btn-lg text-[1.05rem] bg-pale smaller"
          >
            <i className="fa-solid fa-truck-fast" /> View Delivery Info
            <div>
              <div className="text-xs text-muted">
                Dispatch
                {this.state.estimate.prod_days > 1
                  ? ` in ${this.state.estimate.prod_days} working days`
                  : " next business day"}
              </div>
            </div>
          </button>
        )}

        <Modal
          open={this.state.modalIsOpen}
          onClose={this.closeModal}
          classNames={{
            overlay: "custom-overlay",
            modal: "custom-modal modal-small",
          }}
        >
          <div className="modal-scroll">
            <div className="">
              <div className="mb-4 mt-3">
                <div className="mb-3 text-center">
                  <img
                    src={this.props.shipping_icon}
                    className="-mt-6 w-28 mx-auto"
                  />
                  <h2 className="mb-2 mt-0">Delivery Info</h2>
                  <div className="text-sm mt-2 text-brand-600">
                    <i className="fa-solid fa-badge-check mr-1"></i>
                    Tracked and delivered direct to your door
                  </div>
                  <div className="text-sm mt-1 text-brand-600">
                    <i className="fa-solid fa-badge-check mr-1"></i>
                    Fully insured to arrive in perfect condition
                  </div>
                </div>
              </div>
              <div className="bg-pale pt-3 text-sm">
                {this.props.shipping_estimates.length > 1 && (
                  <div className="form-group select optional mb-1 px-3">
                    <label className="mb-2 block">Ship this order to...</label>
                    <select
                      value={this.state.estimate.id}
                      onChange={this.onChange}
                      className="form-control select custom-select"
                    >
                      {this.props.shipping_estimates.map((estimate, i) => (
                        <option key={i} value={estimate.id}>
                          {estimate.title}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <div className="px-3 py-1">
                  Place order
                  <span className="float-right">Today</span>
                </div>
                <div className="px-3 py-1">
                  {this.state.estimate.prod_days > 1
                    ? "Production Time"
                    : "Ready for dispatch"}
                  <span className="float-right">
                    {this.state.estimate.prod_days > 1
                      ? `${this.state.estimate.prod_days} working days`
                      : "by next business day"}
                  </span>
                </div>
                <div className="px-3 py-1">
                  Delivery time
                  <span className="float-right">
                    {this.state.estimate.days}
                  </span>
                </div>
                <div className="px-3 py-1">
                  <strong>On your wall in about...</strong>
                  <strong className="float-right">
                    {this.state.estimate.total_days} working days
                  </strong>
                </div>
              </div>
            </div>
            <div className="card px-3 pt-6">
              <div className="grid grid-cols-3">
                <div className="col-span-2">
                  <span className="mini-heading text-xxs">
                    Estimated delivery on
                  </span>
                  <div className="mb-4 mt-1 text-2xl">
                    {this.state.estimate.date}
                  </div>
                </div>
                <div className="col-span-1 text-right">
                  <span className="mini-heading text-xxs">Cost from</span>
                  <div className="mb-4 mt-1">
                    {this.state.estimate.cost > 0 ? (
                      <div className="mb-0 text-2xl">
                        <span className="font-bold text-xs font-sans relative -top-2 mr-0.5">
                          {window.App.currencySymbol}
                        </span>
                        {this.state.estimate.cost}
                      </div>
                    ) : (
                      "Free"
                    )}
                  </div>
                </div>
              </div>
              <div className="text-sm text-muted mb-3">
                Orders with multiple items may increase the cost shown. Proceed
                to checkout to see final pricing.
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ShippingEstimate;
