import React from "react";
import { useLayoutEffect } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import uploadcare from "uploadcare-widget";
import { Tooltip } from "react-tippy";
import Toggle from "react-toggle";
import PreviewModeSelector from "components/frame-designer/designers/PreviewModeSelector";
import FrameCanvas from "components/frame-designer/designers/FrameCanvas";
import FrameCanvasNew from "components/frame-designer/designers/FrameCanvasNew";
import FrameGallery from "components/frame-designer/designers/FrameGallery";
import GlassModalButton from "components/frame-designer/designers/GlassModalButton";
import FrameColourSelector from "components/frame-designer/designers/FrameColourSelector";
import ShippingEstimate from "components/cart/ShippingEstimate";
import FrameStyleSelector from "components/frame-designer/designers/FrameStyleSelector";
import Personalisation from "components/frame-designer/templates/Personalisation";
import AfterpayBreakdown from "components/afterpay/AfterpayBreakdown";
import CurrencyDisplay from "components/helpers/CurrencyDisplay";
import { Widget } from "@uploadcare/react-widget";
import { RadioGroup } from "@headlessui/react";
import ColourPickers from "components/art/ColourPickers";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ViewInSpace from "components/frame-designer/designers/ViewInSpace";
import { framePreviewURL } from "components/art/constants";

class FrameDesignerUI extends React.Component {
  constructor(props) {
    super();
    this.state = {
      price: "",
      current_image: props.current_image,
      curr_frame_styles: props.frame_styles,
      frame_style: props.frame_styles.find(
        (obj) => obj.id === props.frame_style_id
      ),
      mat_style: props.mat_styles.find((obj) => obj.id === props.mat_style_id),
      mat: props.mats.find((obj) => obj.id === props.mat_id),
      glass_type: props.glass_types.find(
        (obj) => obj.id === props.glass_type_id
      ),
      cf: props.custom_frame,
      gallery_images: props.gallery_images ? props.gallery_images : [],
      tax_rate: props.tax_rate + 1,
      wallColour: { label: "Resene Quarter Black White", color: "#F3F3F0" },
      canvasAssetsLoaded: false,
      cropOrientationPortrait: false,
      image_crop_ratio: props.image_crop_ratio,
    };
    this.filterStylesByColour = this.filterStylesByColour.bind(this);
    this.frameColourByName = this.frameColourByName.bind(this);
    this.saveValues = this.saveValues.bind(this);
    this.updateAvailableMatStyles = this.updateAvailableMatStyles.bind(this);
    this.saveGlassType = this.saveGlassType.bind(this);
    this.saveMatStyle = this.saveMatStyle.bind(this);
    this.saveMat = this.saveMat.bind(this);
    this.updateFrameStyle = this.updateFrameStyle.bind(this);
    this.updateFrameColour = this.updateFrameColour.bind(this);
    this.customFrameAttr = this.customFrameAttr.bind(this);
    this.templateAttr = this.templateAttr.bind(this);
    this.personalisedTexts = this.personalisedTexts.bind(this);
    this.saveCustomFrame = this.saveCustomFrame.bind(this);
    this.saveFail = this.saveFail.bind(this);
    this.updatePrice = this.updatePrice.bind(this);
    this.savePrices = this.savePrices.bind(this);
    this.displayTotalPrice = this.displayTotalPrice.bind(this);
    this.totalWidth = this.totalWidth.bind(this);
    this.totalHeight = this.totalHeight.bind(this);
    this.totalDimensions = this.totalDimensions.bind(this);
    this.personaliseUpdate = this.personaliseUpdate.bind(this);
    this.updateFramePreview = this.updateFramePreview.bind(this);
    this.setCanvasAssetsLoaded = this.setCanvasAssetsLoaded.bind(this);
    this.currFramePreview = this.currFramePreview.bind(this);
    this.onPhotoUpload = this.onPhotoUpload.bind(this);
    this.onClearFile = this.onClearFile.bind(this);
    this.orientationUpdate = this.orientationUpdate.bind(this);
  }

  componentDidMount() {
    const script = document.createElement("script");

    script.src = "//widget.reviews.io/modern-widgets/nuggets.js";
    script.async = true;

    document.body.appendChild(script);

    if (this.props.frame_colours) {
      this.updateFrameStyle({
        curr_frame_styles: this.filterStylesByColour(
          this.state.frame_style.colour_list[0]
        ),
        frame_style: this.state.frame_style,
        frame_colour: this.frameColourByName(
          this.state.frame_style.colour_list[0]
        ),
      });
    } else {
      this.updateFrameStyle({
        curr_frame_styles: this.props.frame_styles,
        frame_style: this.state.frame_style,
      });
    }
  }

  filterStylesByColour(name) {
    return this.props.frame_styles.filter(
      (element) => element.colour_list.indexOf(name) > -1
    );
  }

  frameColourByName(colour) {
    return this.props.frame_colours.find((obj) => obj.name === colour);
  }

  saveValues(params) {
    this.setState({ canvasAssetsLoaded: false, ...params }, () => {
      this.updateAvailableMatStyles();
    });
  }

  updateAvailableMatStyles() {
    if (this.state.frame_style.mat_styles) {
      let same_mat = this.state.frame_style.mat_styles.find(
        (obj) => obj.id === this.state.mat_style.id
      );
      let new_mat = same_mat ? same_mat : this.state.frame_style.mat_styles[1];

      this.setState({ mat_style: new_mat }, () => {
        this.updatePrice();
      });
    } else {
      this.updatePrice();
    }
  }

  saveGlassType(glass_type) {
    this.setState({ glass_type: glass_type }, () => {
      this.updatePrice();
    });
  }

  saveMatStyle(mat_style) {
    this.setState({ mat_style: mat_style }, () => {
      this.updatePrice();
    });
  }

  saveMat(mat_colour) {
    console.log(mat_colour);
    this.setState({ mat: mat_colour }, () => {
      this.updatePrice();
    });
  }

  updateFrameStyle(params) {
    this.saveValues(params);
  }

  updateFrameColour(params) {
    this.setState(
      {
        curr_frame_styles: this.filterStylesByColour(params.frame_colour.name),
        frame_style: this.filterStylesByColour(params.frame_colour.name)[0],
        frame_colour: this.frameColourByName(params.frame_colour.name),
      },
      () => {
        params["frame_style"] = this.state.frame_style;
        this.saveValues(params);
      }
    );
  }

  customFrameAttr() {
    return {
      custom_frame_id: this.props.custom_frame_id,
      image_id: this.state.current_image.id,
      paper_type_id: this.props.paper_type.id,
      frame_size_id: this.props.frame_size_id,
      custom_frame: {
        frame_style_colour_id: this.state.frame_style.fsc_id,
        height: this.state.cf.height,
        width: this.state.cf.width,
        unit: this.state.cf.unit,
        glass_type_id: this.state.glass_type.id,
        mat_style_id: this.state.mat_style.id,
        mat_id: this.state.mat.id,
        art_type_id: this.props.art_type_id,
        image_url: this.state.image_url,
        image_path: this.state.image_path,
        template_id: this.props.template_id,
        country: this.props.current_country,
        plate_attributes: this.props.plate
          ? this.props.plate
          : { _destroy: "1" },
      },
    };
  }

  templateAttr() {
    return {
      template_purchase_id: this.props.template_purchase_id,
      image_id: this.state.current_image.id,
      template_purchase: {
        template_variant_id: this.props.template_variants.find(
          (item) => item.frame_style_colour_id == this.state.frame_style.fsc_id
        ).id,
        glass_type_id: this.state.glass_type.id,
        template_id: this.props.template_id,
        preview_url: this.state.image_url,
        personalisation_option_id: this.props.p_option_id,
        personalised_texts_attributes: this.personalisedTexts(),
      },
    };
  }

  personalisedTexts() {
    if (this.props.p_texts) {
      let personalisedTexts = this.props.p_texts.map((item) => {
        return {
          text_body: item.text_body,
          colour: item.colour,
          personalisation_text_id: item.og_id,
          id: item.pers_id,
        };
      });
      return personalisedTexts;
    }
  }

  saveCustomFrame(e) {
    e.preventDefault();
    this.setState({ savingInProgress: true });
    $.ajax({
      method: this.props.is_edit_mode ? "PUT" : "POST",
      url: this.props.post_to_cart_url,
      data: this.props.is_template
        ? this.templateAttr()
        : this.customFrameAttr(),
    })
      .done(
        function (data) {
          window.location.href = data.redirect_to;
        }.bind(this)
      )
      .fail(this.saveFail);
  }

  saveFail(data) {
    this.setState({ savingInProgress: false });
    console.log(data.responseJSON.error);
    this.setState({ error: data.responseJSON.error });
  }

  updatePrice(e) {
    this.setState({ priceLoading: true });
    $.ajax({
      method: "GET",
      url: this.props.pricing_url,
      data: this.props.is_template
        ? this.templateAttr()
        : this.customFrameAttr(),
    }).done(this.savePrices);
  }

  savePrices(data) {
    this.setState({
      price_inc_tax: data.price,
      glass_prices: data.glass_prices,
      price_sku_code: data.price_sku_code,
      priceLoading: false,
    });
  }

  displayTotalPrice() {
    return this.state.price_inc_tax && parseFloat(this.state.price_inc_tax);
  }

  totalWidth() {
    let matX = this.state.mat_style.edges_x;
    let moulding = this.state.frame_style.width * 2 - 14;
    let artwork = parseInt(this.state.cf.width_mm);
    return (matX + moulding + artwork) / 10;
  }

  totalHeight() {
    let matY = this.state.mat_style.edges_y;
    let moulding = this.state.frame_style.width * 2 - 14;
    let artwork = parseInt(this.state.cf.height_mm);
    return (matY + moulding + artwork) / 10;
  }

  totalDimensions() {
    return (
      <span>
        Final size {this.totalWidth()} × {this.totalHeight()}cm
      </span>
    );
  }

  personaliseUpdate(options, url) {
    console.log("personaliseUpdate");
    console.log(url);
    let img = this.state.current_image;
    img.url = url;

    this.setState({
      p_texts: options,
      current_image: img,
      canvasAssetsLoaded: false,
    });
  }

  updateFramePreview(data) {
    this.setState({ current_image: data });
  }

  currFramePreview() {
    const url = framePreviewURL({
      mat_width: this.state.mat_style.top,
      width: this.state.cf.width_mm,
      height: this.state.cf.height_mm,
      artwork_url: this.state.current_image.url,
      style: this.state.frame_style.pattern_slug,
      moulding_width: this.state.frame_style.width,
      frame_type: this.state.frame_style.frame_type,
      maxPX: 500,
    });
    return url;
  }

  setCanvasAssetsLoaded(e) {
    if (this.state.canvasAssetsLoaded == false) {
      this.setState({ canvasAssetsLoaded: true });
    }
  }

  onPhotoUpload(fileInfo) {
    let newImage = {
      width: fileInfo.originalImageInfo.width,
      height: fileInfo.originalImageInfo.height,
      filename: fileInfo.name,
      external_id: fileInfo.uuid,
      host: "uploadcare",
      path: fileInfo.uuid + "/file_" + fileInfo.uuid,
      source: fileInfo.sourceInfo.source,
      url: fileInfo.originalUrl,
      filesize: fileInfo.size,
      format: fileInfo.originalImageInfo.format,
      art_type_id: this.props.art_type_id,
      cx: fileInfo.crop.left,
      cy: fileInfo.crop.top,
      cw: fileInfo.crop.width,
      ch: fileInfo.crop.height,
    };

    $.ajax({
      method: "PUT",
      url: this.props.post_image_url,
      data: {
        image: newImage,
      },
    }).done(
      function (data, textStatus, jqXHR) {
        console.log(data.image);
        this.setState({ current_image: data.image });
      }.bind(this)
    );
  }

  onClearFile() {
    this.setState({ current_image: this.props.current_image });
  }

  outstandingTasks() {
    return this.props.is_template ? this.state.current_image.id == null : false;
  }

  orientationUpdate() {
    const { cropOrientationPortrait, cf } = this.state;
    let flipped;

    if (!cropOrientationPortrait) {
      flipped = { ...cf };
      flipped.width_mm = cf.height_mm;
      flipped.height_mm = cf.width_mm;
      flipped.width = cf.height;
      flipped.height = cf.width;
    } else {
      flipped = this.props.custom_frame;
    }

    this.setState({
      cropOrientationPortrait: !this.state.cropOrientationPortrait,
      cf: flipped,
      image_crop_ratio: `${parseInt(flipped.width)}x${parseInt(
        flipped.height
      )}`,
    });
  }

  render() {
    let canvasFrame = (
      <FrameCanvasNew
        maxWidth={600}
        preview_url={this.state.current_image.url}
        width_mm={this.state.cf.width_mm}
        height_mm={this.state.cf.height_mm}
        frame_style={this.state.frame_style}
        mat_style={this.state.mat_style}
        mat={this.state.mat}
        setCanvasAssetsLoaded={this.setCanvasAssetsLoaded}
      />
    );

    let framePreviewUploader = (
      <FilepickerPreviewUpload
        art_type_id={this.props.art_type_id}
        preview_upload_path={this.props.preview_upload_path}
        ratio={`${this.state.cf.width_mm}x${this.state.cf.height_mm}`}
        previewImageCallback={this.updateFramePreview}
        current_image={this.state.current_image}
      />
    );

    return (
      <div className="md:mx-10">
        <div className="md:mt-5">
          <div className="md:grid md:grid-cols-12 md:gap-x-8 ">
            <div className="md:order-2 md:col-span-7 ">
              <div className="">
                <InteriorPreviews
                  width_cm={this.totalWidth()}
                  height_cm={this.totalHeight()}
                  interiors={this.props.interiors}
                  onWallColourChange={(e) => this.setState({ wallColour: e })}
                  wallColour={this.state.wallColour}
                  gallery_images={this.state.gallery_images.concat(
                    this.state.frame_style.gallery_images
                  )}
                  canvasFrame={canvasFrame}
                  canvasAssetsLoaded={this.state.canvasAssetsLoaded}
                  framePreviewUploader={framePreviewUploader}
                  includes_print={this.props.includes_print}
                  currFramePreview={this.currFramePreview()}
                />
              </div>
            </div>

            <div className="md:order-3 md:col-span-5">
              <div className="mx-3">
                <h1 className="text-4xl md:pt-2 mb-1 md:mt-3 md:mt-0">
                  {this.props.template_title
                    ? this.props.template_title
                    : this.state.frame_style.title}
                </h1>
                <div className="mb-5 mt-2 text-muted">
                  {this.props.template_id ? (
                    <div>
                      <div className="mb-1">{this.props.template_desc}</div>
                      <small className="text-muted">
                        {this.props.template_specs} · {this.totalDimensions()}
                      </small>
                    </div>
                  ) : (
                    this.state.frame_style.excerpt
                  )}
                  {this.props.p_options && (
                    <img
                      src={this.props.benefits_image}
                      className="img-fluid pt-3 w-4/5"
                    />
                  )}
                </div>
                <hr className="mb-4" />

                {this.props.is_template && (
                  <>
                    {this.props.allow_orientation &&
                      this.state.current_image.id == null && (
                        <div className="my-4">
                          <Toggle
                            id="orientation"
                            icons={false}
                            defaultChecked={!this.state.cropOrientationPortrait}
                            onChange={this.orientationUpdate}
                          />
                          <label
                            style={{ top: "-8px", position: "relative" }}
                            className="ml-2 mini-heading text-stone-400"
                            htmlFor="orientation"
                          >
                            <span
                              className={
                                !this.state.cropOrientationPortrait
                                  ? "text-stone-900"
                                  : null
                              }
                            >
                              Portrait
                            </span>{" "}
                            ·
                            <span
                              className={
                                this.state.cropOrientationPortrait
                                  ? "text-stone-900"
                                  : null
                              }
                            >
                              {" "}
                              Landscape
                            </span>
                          </label>
                        </div>
                      )}

                    <div className="template-upload">
                      <div className="text-stone-900 mb-2 text-sm">
                        First step: <span className="text-muted"></span>
                      </div>
                      <Widget
                        validators={[
                          minResolution(
                            this.props.min_pixels_l,
                            this.props.min_pixels_s
                          ),
                        ]}
                        onChange={(info) => this.onPhotoUpload(info)}
                        onFileSelect={(file) => !file && this.onClearFile()}
                        tabs="file facebook instagram dropbox url"
                        imagesOnly="true"
                        previewStep="true"
                        clearable="true"
                        store="auto"
                        crop={this.state.image_crop_ratio}
                        id="file"
                        localeTranslations={UPLOADCARE_LOCALE_TRANSLATIONS}
                      />
                    </div>
                  </>
                )}

                {this.props.p_texts && (
                  <Personalisation
                    onUpdate={this.personaliseUpdate}
                    canvas_details={this.props.canvas_details}
                    options={this.props.p_texts}
                    overlays={this.props.p_overlays}
                    current_image={this.state.current_image}
                  />
                )}
                <div className="text-muted text-sm">
                  {this.props.template_id && (
                    <div className="text-stone-900 mb-2 text-sm">
                      Frame style:{" "}
                      <span className="text-muted">
                        {this.state.frame_style.title}
                      </span>
                    </div>
                  )}
                </div>
                {this.props.frame_colours && this.state.frame_colour && (
                  <FrameColourSelector
                    saveValues={this.updateFrameColour}
                    options={this.props.frame_colours}
                    defaultChecked={this.state.frame_colour}
                  />
                )}
                <FrameStyleSelector
                  saveValues={this.updateFrameStyle}
                  options={this.state.curr_frame_styles}
                  defaultChecked={this.state.frame_style}
                />
                <hr className="my-2 mb-6" />
              </div>
              <div className="mx-3">
                {!this.props.is_template && (
                  <>
                    <MatStyleSelector
                      saveValues={this.saveMatStyle}
                      options={this.state.frame_style.mat_styles}
                      selected={this.state.mat_style}
                      artwork_width_mm={this.state.cf.width_mm}
                      artwork_height_mm={this.state.cf.height_mm}
                      max_glass_long_mm={this.props.max_glass_long_mm}
                      max_glass_short_mm={this.props.max_glass_short_mm}
                      includes_print={this.props.includes_print}
                      selected_mat_colour={this.state.mat}
                      mat_colour_options={this.props.mats}
                      saveMatColourValues={this.saveMat}
                      current_country={this.props.current_country}
                    />
                    <hr className="my-4" />
                  </>
                )}
                <div className="flex">
                  <div className="text-stone-900 mb-2 text-sm grow">
                    Total size:
                    <span className="text-muted">
                      {" "}
                      {Math.round(this.totalWidth())}cm ×{" "}
                      {Math.round(this.totalHeight())}cm
                    </span>
                  </div>
                  <div className="relative -top-2">
                    <ViewInSpace
                      width_cm={Math.round(this.totalWidth())}
                      height_cm={Math.round(this.totalHeight())}
                    />
                  </div>
                </div>
              </div>
              <div className="md:mb-3 p-2.5 bg-white sticky bottom-0 md:top-0 z-40 ">
                <GlassModalButton
                  wholesale={this.props.wholesale}
                  tax_rate={this.state.tax_rate}
                  setActive={this.saveGlassType}
                  glass_prices={this.state.glass_prices}
                  priceLoading={this.state.priceLoading}
                  glass_types={this.props.glass_types}
                  glass_type={this.state.glass_type}
                  price={this.displayTotalPrice()}
                  cartText={
                    this.props.custom_frame_id
                      ? "Save & Continue"
                      : "Add to cart"
                  }
                  error={this.state.error}
                  afterpayUI={this.props.afterpayUI}
                  addToCart={this.saveCustomFrame}
                  savingInProgress={this.state.savingInProgress}
                  outstandingTasks={this.outstandingTasks()}
                />
              </div>
              <div className="mx-3">
                <ShippingEstimate
                  shipping_icon={this.props.shipping_icon}
                  shipping_estimates={this.props.shipping_estimates}
                  print_and_frame={this.props.includes_print}
                  country={this.props.country_name}
                  location={this.props.product_sku}
                />
                <div
                  className="reviews-io-nuggets-widget mt-10"
                  widget-id="ITzQhxBJdm7lFH26"
                  data-store-name="www.framefox.co"
                  lang="en"
                  data-sku=""
                  tags=""
                  branch=""
                ></div>
              </div>

              <div className="mx-3">
                <Features
                  includes_print={this.props.includes_print}
                  whats_included={this.props.whats_included}
                  paper_type={this.props.paper_type}
                  mounting={this.props.mounting}
                  backing={this.props.backing}
                  hardware={this.props.hardware}
                  country_name={this.props.country_name}
                  glass_types={this.props.glass_types}
                  glass_types_sentence={this.props.glass_types_sentence}
                  mat_available={!this.props.p_texts}
                  frame_title={this.state.frame_style.title}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const minResolution = (width, height) => {
  return function (fileInfo) {
    const imageInfo = fileInfo.originalImageInfo;
    const resolution = width * height;
    if (imageInfo !== null) {
      if (imageInfo.width * imageInfo.height < resolution) {
        throw new Error("minResolutionError");
      }
    }
  };
};

class FilepickerPreviewUpload extends React.Component {
  constructor(props) {
    super();
    this.state = {};
    this.saveSuccess = this.saveSuccess.bind(this);
    this.saveFail = this.saveFail.bind(this);
    this.onWidgetChange = this.onWidgetChange.bind(this);
    this.widgetApi = React.createRef(null);
  }

  onWidgetChange(data) {
    let newImage = {
      width: data.crop ? data.crop.width : data.originalImageInfo.width,
      height: data.crop ? data.crop.height : data.originalImageInfo.height,
      filename: data.name,
      external_id: data.uuid,
      host: "uploadcare",
      path: data.uuid + "/file_" + data.uuid,
      source: data.sourceInfo.source,
      url: data.cdnUrl,
      filesize: data.size,
      format: data.originalImageInfo.format,
      art_type_id: this.props.art_type_id,
    };

    $.ajax({
      method: "POST",
      url: this.props.preview_upload_path,
      data: {
        image: newImage,
      },
    })
      .done(this.saveSuccess)
      .fail(this.saveFail);
  }

  saveSuccess(data) {
    console.log(data.image);
    this.props.previewImageCallback(data.current_image);
  }

  saveFail() {
    console.log("Failed to save");
  }

  render() {
    return (
      <div className="mt-4">
        <Widget
          ref={this.widgetApi}
          onChange={(info) => this.onWidgetChange(info)}
          tabs="file facebook instagram dropbox url"
          imagesOnly="true"
          previewStep="true"
          clearable="true"
          store="auto"
          crop={this.props.ratio}
          id="file"
          localeTranslations={UPLOADCARE_LOCALE_TRANSLATIONS_PREVIEW}
        />
        <Tooltip
          title="Upload an image into the frame (won't be printed)"
          distance={10}
          position="top"
          arrow={true}
        >
          <button
            className="btn btn-sm btn-pale text-brand-600"
            onClick={() => this.widgetApi.current.openDialog()}
            type="button"
          >
            <i className="fa-solid fa-eye mr-2" />
            {this.props.current_image.id
              ? " Replace preview image"
              : " Add a preview image"}
          </button>
        </Tooltip>
      </div>
    );
  }
}

const CanvasContainer = (props) => (
  <>
    <div
      className="relative aspect-1"
      style={{ backgroundColor: props.wallColour.color }}
    >
      <div className="absolute w-full h-full flex justify-center items-center">
        <div
          className={classNames(
            props.width_cm > props.height_cm ? "w-[85%]" : "h-[85%]",
            !props.canvasAssetsLoaded && "blur-sm",
            "relative"
          )}
        >
          {props.canvasFrame}
        </div>
      </div>
    </div>
  </>
);

const InteriorPreviews = (props) => (
  <>
    {window.innerWidth < 768 ? (
      <Swiper
        slidesPerView={1}
        modules={[Pagination]}
        pagination={{
          dynamicBullets: true,
        }}
      >
        <SwiperSlide>
          <CanvasContainer {...props} />
        </SwiperSlide>

        {props.interiors.map((interior, i) => (
          <SwiperSlide key={i}>
            <Interior
              width_cm={props.width_cm}
              height_cm={props.height_cm}
              wallColour={props.wallColour}
              currFramePreview={props.currFramePreview}
              {...interior}
            />
          </SwiperSlide>
        ))}
        {props.gallery_images.map((gallery_image, i) => (
          <SwiperSlide key={i}>
            <GalleryImage
              wallColour={props.wallColour}
              background={gallery_image.background}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    ) : (
      <>
        <CanvasContainer {...props} />
        {!props.includes_print && (
          <div className="text-center">{props.framePreviewUploader}</div>
        )}
        {/* <div className="mt-5 py-4 sticky top-0 bg-white z-40">
          <div className="font-bold mb-1">
            Paint these walls with Tint Paint
          </div>
          <ColourPickers
            colour={props.wallColour}
            onColourChange={props.onWallColourChange}
          />
        </div> */}

        <div className="hidden md:block mt-8">
          {props.interiors.map((interior, i) => (
            <Interior
              key={i}
              width_cm={props.width_cm}
              height_cm={props.height_cm}
              wallColour={props.wallColour}
              currFramePreview={props.currFramePreview}
              {...interior}
            />
          ))}
          {props.gallery_images.map((gallery_image, i) => (
            <GalleryImage
              key={i}
              wallColour={props.wallColour}
              background={gallery_image.background}
            />
          ))}
        </div>
      </>
    )}
  </>
);

const imageStyles = (props) => {
  let positionStyle = {
    right: `${props.frame_x / 10}%`,
    bottom: `${props.frame_y / 10}%`,
  };
  let scaledMax = props.max_frame_size / 10;
  let longest = Math.max(props.width_cm, props.height_cm);
  let scale = Math.min(longest * 0.5, scaledMax);
  let scaleOperation =
    props.width_cm / props.height_cm > 1
      ? { width: `${scale}%` }
      : { height: `${scale}%` };

  return { ...scaleOperation, ...positionStyle };
};

const GalleryImage = (props) => (
  <>
    <div
      className="relative aspect-1 mb-8"
      style={{ backgroundColor: props.wallColour.color }}
    >
      <img src={props.background} className="absolute w-full h-full" />
    </div>
  </>
);

const Interior = (props) => (
  <>
    <div
      className="relative aspect-1 mb-8"
      style={{ backgroundColor: props.wallColour.color }}
    >
      <img src={props.background} className="absolute w-full h-full" />
      <div className="absolute w-full h-full flex justify-center items-center">
        <img
          src={props.currFramePreview}
          style={imageStyles(props)}
          className="interior-frame-shadow relative"
        />
      </div>
      <img
        src={props.shadow_url}
        className="absolute w-full h-full opacity-25"
      />
    </div>
  </>
);

const Features = (props) => (
  <>
    <div className="mt-12 font-bold">Museum-Quality in Every Frame</div>

    {props.paper_type.title != "No Printing" && (
      <div className="border-t border-gray-200 pt-5 mt-5">
        <dt className="mini-heading text-gray-900">Printing & Paper</dt>
        <dd className="mt-2 text-sm text-gray-500">
          Your image will be printed on gallery-quality {props.paper_type.title}{" "}
          paper using 12 colour archival pigment inks on the latest Epson
          printers.
        </dd>
      </div>
    )}

    <div className="border-t border-gray-200 pt-5 mt-5">
      <dt className="mini-heading text-gray-900">
        The {props.frame_title} Frame
      </dt>
      <dd className="mt-2 text-sm text-gray-500">
        Sustainably sourced, solid wood frame handmade in {props.country_name}.
      </dd>
    </div>
    <div className="border-t border-gray-200 pt-5 mt-5">
      <dt className="mini-heading text-gray-900">Glazing</dt>
      <dd className="mt-2 text-sm text-gray-500">
        {props.glass_types_sentence}
      </dd>
    </div>
    <div className="border-t border-gray-200 pt-5 mt-5">
      <dt className="mini-heading text-gray-900">Borders</dt>
      <dd className="mt-2 text-sm text-gray-500">
        Optional acid-free bevel-cut {window.App.mat} available in a range of
        sizes. Go big to increase the final size of your piece.
      </dd>
    </div>
    <div className="border-t border-gray-200 pt-5 mt-5">
      <dt className="mini-heading text-gray-900">Hanging Hardware</dt>
      <dd className="mt-2 text-sm text-gray-500">{props.hardware}</dd>
    </div>
  </>
);

const WhatsIncludedCustomOLD = (props) => (
  <div className="mt-5">
    {props.includes_print && (
      <div className="row bg-brand-100">
        <div className="container">
          <div className="how-it-works-block sm">
            <div className="row gutter-40">
              <div className="col-md-14 order-1">
                <img
                  className="img-fluid"
                  src={props.whats_included.printing}
                />
              </div>
              <div className="col-md-9 offset-md-1 my-auto order-md-1">
                <h2 className="my-3">Printing & Paper</h2>
                <p>
                  Your image will be printed on gallery-quality{" "}
                  {props.paper_type.title} paper using 12 colour archival
                  pigment inks on the latest Epson printers.
                </p>
                <p>{props.mounting}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}

    <div className="bg-brand-100 pt-5 row gutter-flush">
      <div className="col-md-8 offset-md-2 my-auto">
        <div className="mx-1">
          <div className="mb-4">
            <h2 className="my-3">The {props.frame_title} Frame</h2>
            <p>
              Sustainably sourced, solid wood frame handmade in{" "}
              {props.country_name}.
            </p>
            <p>
              {props.glass_types.length > 1 ? (
                <span>Your choice of {props.glass_types_sentence} </span>
              ) : (
                <span>{props.glass_types_sentence} glazing </span>
              )}
              for protection and crystal clear viewing of your artwork.
            </p>
            <p>
              {props.mat_available &&
                `Optional acid-free bevel-cut ${window.App.mat} available in standard (50mm) or oversized (80mm).`}
            </p>
            <p>{props.backing}</p>

            <div className="row gutter-0">
              <div className="col-md-15">
                <p>{props.hardware}</p>
              </div>
              <div className="col-md-9">
                <img className="img-fluid" src={props.hardware_img} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-14">
        <div
          className="room-container bg-present"
          style={{ backgroundImage: `url(${props.frame_img})` }}
        ></div>
      </div>
    </div>
  </div>
);

const WhatsIncludedCanvas = (props) => (
  <div>
    <div className="row bg-brand-100 mt-5 pt-5">
      <div className="container">
        <div className="how-it-works-block sm">
          <div className="row gutter-40">
            <div className="col-md-14 order-1">
              <img className="img-fluid" src={props.printing_img} />
            </div>
            <div className="col-md-9 offset-md-1 my-auto order-md-1">
              <h2 className="my-3">Premium Canvas</h2>
              <p className="lead text-muted">
                Your image will be printed on premium cotton/poly blend canvas
                using 12 colour archival pigment inks on the latest Epson
                printers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="bg-brand-100  row gutter-flush">
      <div className="col-md-8 offset-md-2 my-auto">
        <div className="mx-1">
          <div className="mb-4">
            <h2 className="my-3">The {props.frame_title} Frame</h2>
            <p className="lead text-muted">
              Sustainably sourced, solid wood frame handmade in{" "}
              {props.country_name}.
            </p>
            <p className="lead text-muted">
              Your canvas print will be stretched then bordered with an elegant
              custom float frame.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-14">
        <div
          className="room-container bg-present"
          style={{ backgroundImage: `url(${props.frame_img})` }}
        ></div>
      </div>
    </div>
  </div>
);

const UploadImageButton = ({ upload_path }) => (
  <div>
    <a
      href={upload_path}
      className="btn btn-primary block w-full text-lg btn-lg pt-2 pb-3 text-center disabled:bg-brand-400 disabled:cursor-not-allowed"
    >
      <div className="text-xs text-brand-300  mt-0.5">Next Step:</div>
      Upload my photo
    </a>
  </div>
);

class MatStyleSelector extends React.Component {
  constructor(props) {
    super();
    this.state = {
      colours_expanded: false,
      border_type: props.selected.border,
    };
    this.forceMatForSmall = this.forceMatForSmall.bind(this);
    this.forceUnmattedForLarge = this.forceUnmattedForLarge.bind(this);
  }

  componentDidMount() {
    if (this.forceUnmattedForLarge()) {
      this.setState({ border_type: "no_border" });
      this.props.saveValues(this.props.options[0]);
    }
  }

  forceMatForSmall() {
    return this.props.artwork_width_mm + this.props.artwork_height_mm < 300;
  }

  forceUnmattedForLarge() {
    let w = this.props.artwork_width_mm + 100;
    let h = this.props.artwork_height_mm + 100;

    return (
      Math.max(w, h) > this.props.max_glass_long_mm ||
      Math.min(w, h) > this.props.max_glass_short_mm
    );
  }

  render() {
    return (
      <div>
        {this.props.selected.border != "no_border" && (
          <div className="text-muted mb-2 text-sm">
            <span className="text-stone-900 mb-2">Border:</span> Premium{" "}
            {this.props.selected.bottom}mm bevel-cut border
            {this.props.current_country == "nz" && (
              <>
                {" "}
                in{" "}
                <button
                  className="text-brand-600 lowercase"
                  onClick={(e) =>
                    this.setState({
                      colours_expanded: !this.state.colours_expanded,
                    })
                  }
                >
                  {this.props.selected_mat_colour.title}
                </button>
              </>
            )}
          </div>
        )}

        {this.forceUnmattedForLarge() && (
          <div className="text-muted text-sm mt-3">
            {window.App.matTitle} unavailable for this frame type/size
          </div>
        )}

        <RadioGroup
          value={this.props.selected}
          onChange={this.props.saveValues}
          className="mt-2"
        >
          <div className="">
            {this.props.options.map((radio) => (
              <RadioGroup.Option
                key={radio.id}
                value={radio}
                disabled={
                  radio.border != "no_border" && this.forceUnmattedForLarge()
                }
                className={({ active, checked }) =>
                  classNames(
                    radio.border != "no_border" && this.forceUnmattedForLarge()
                      ? "cursor-not-allowed opacity-25"
                      : "cursor-pointer focus:outline-none",
                    active ? "ring-2 ring-brand-600 ring-offset-2" : "",
                    checked
                      ? "bg-brand-600 text-white hover:bg-brand-500"
                      : "bg-brand-100 ring-1 ring-inset ring-white  text-gray-900 hover:bg-gray-50",
                    "items-center justify-center rounded-md mr-2 mb-2 py-2.5 px-2.5 text-sm font-semibold  inline-block cursor-pointer disabled:cursor-not-allowed disabled:opacity-25"
                  )
                }
              >
                <RadioGroup.Label as="span">{radio.title}</RadioGroup.Label>
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>

        {this.state.colours_expanded && (
          <MatSelector
            saveValues={this.props.saveMatColourValues}
            options={this.props.mat_colour_options}
            selected={this.props.selected_mat_colour}
          />
        )}
      </div>
    );
  }
}

const BorderTooltip = ({ img, text }) => (
  <div className="border-tooltip">
    <div className="img-holder">
      <img src={img} />
    </div>
    <small>{text}</small>
  </div>
);

const MatSelector = (props) => (
  <div>
    <RadioGroup
      value={props.selected}
      onChange={props.saveValues}
      className="mt-2"
    >
      <div className="">
        {props.options.map((radio, i) => (
          <RadioGroup.Option
            key={radio.id}
            value={radio}
            className={({ active, checked }) =>
              classNames(
                active ? "ring-2 ring-brand-600 ring-offset-2" : "",
                checked
                  ? "bg-brand-600 text-white hover:bg-brand-500"
                  : "bg-brand-100 ring-1 ring-inset ring-white text-gray-900 hover:bg-gray-50",
                "items-center justify-center rounded-md mr-2 mb-2 py-2.5 px-2.5 text-sm font-semibold  inline-block cursor-pointer"
              )
            }
          >
            <RadioGroup.Label as="span">{radio.title}</RadioGroup.Label>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  </div>
);

export default FrameDesignerUI;
